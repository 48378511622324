.item {
  @apply relative grid cursor-pointer overflow-hidden rounded-md bg-theme-cardbg text-base; // transition-all
  @apply border-l-[6px];

  //colorful
  /* &:nth-child(4n + 1) {
    @apply border-theme-orange;
    & > div {
      @apply bg-theme-orange/10;
    }
    button {
      @apply bg-theme-orange;
    }
  }
  &:nth-child(4n + 2) {
    @apply border-theme-purple;
    & > div {
      @apply bg-theme-purple/10;
    }
    button {
      @apply bg-theme-purple;
    }
  } */
  &:nth-child(2n + 1) {
    @apply border-theme-blue;
    & > div {
      @apply bg-theme-blue/10;
    }
    button {
      @apply bg-theme-blue;
    }
  }
  &:nth-child(2n + 2) {
    @apply border-theme-green;
    & > div {
      @apply bg-theme-green/10;
    }
    button {
      @apply bg-theme-green;
    }
  }

  & > div {
    @apply flex justify-between p-3;
  }

  h3 {
    @apply font-bold;
  }

  p {
    @apply box-content overflow-hidden px-3 py-2;
  }

  button {
    @apply relative -m-1 block h-8 w-8 overflow-hidden rounded-[50%] p-4 transition-all;
    @apply flex-shrink-0;

    & > span {
      @apply absolute left-2 top-2.5 h-1 w-4 bg-theme-cardbg transition-all;

      &:first-of-type {
        @apply left-1.5 origin-left rotate-45;
      }
      &:last-of-type {
        @apply left-2.5 origin-right -rotate-45;
      }
    }
  }

  &.opened {
    p {
      @apply py-3;
    }
    button {
      @apply rounded;

      & > span {
        @apply left-1 top-3.5 origin-center;

        &:first-of-type {
          @apply w-6 translate-x-[0.025rem] rotate-45;
        }
        &:last-of-type {
          @apply w-6 -translate-x-[0.025rem] -rotate-45;
        }
      }
    }
  }

  // &:hover {
  //   will-change: auto;
  //   transform: scale(1.1);
  // }
}
