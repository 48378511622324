.link {
  max-height: 20vw;
  width: 15vh;
  padding: 0.5rem;

  img {
    max-height: 100%;
    width: 100%;
  }
}
