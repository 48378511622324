.root {
  @apply mt-28 flex min-h-screen flex-col items-center justify-center gap-20 p-4;

  & > img {
    width: 160px;
    height: auto;
  }
}

.title {
  /* @apply relative flex-fill font-jetbrains text-4xl font-bold; */
  background: linear-gradient(to left, #6ab6f8, #bf75d3, #ec764f);
  -webkit-text-fill-color: transparent;
  @apply w-fit bg-clip-text text-center font-black;
  @apply text-[2.5rem] leading-[1.1] md:text-[4rem] lg:text-[5rem];
  @apply mx-auto md:mx-0;
}

@screen md {
  .root {
    @apply mt-6 p-24;

    & > img {
      width: fit-content;
    }
  }
}
