.wrap {
  @apply relative mt-[1.1em] flex w-fit rounded-md text-xl;

  &:after {
    content: ' ';
    @apply absolute inset-[-3px] rounded-inherit text-white; //bg-gradient-to-r
    /* @apply bg-neutral-700/25 p-4 shadow-md shadow-theme-blue/40 ring-2 ring-theme-blue/60 backdrop-blur; */
    --tw-gradient-from-position: -20%;
    background-image: linear-gradient(to right, #67df5b var(--tw-gradient-from-position), #6ab6f8 var(--tw-gradient-to-position));
    z-index: -1;
  }
  &:nth-child(2n + 2) {
    &::after {
      /* @apply bg-gradient-to-l; */
      background-image: linear-gradient(
        to left,
        #67df5b var(--tw-gradient-from-position),
        /* #bf75d3 var(--tw-gradient-via-position), */ #6ab6f8 var(--tw-gradient-to-position)
      );
    }
    .badge {
      @apply border-theme-blue bg-theme-blue;
    }
  }

  &:has(.badge) .item {
    @apply pt-7;
  }
}
.item {
  @apply relative w-full whitespace-pre-line rounded-inherit bg-black/60 p-4;

  /* & > a {
    @apply border-b border-white pb-1;
  } */

  &:is(ul) {
    @apply space-y-2;
  }

  & > li {
    @apply list-inside list-[disclosure-closed]; // list-image-[url(/logo96.png)];
    &::marker {
      @apply text-theme-orange;
    }
  }

  & b {
    @apply mt-2 inline-block rounded border border-white/70 bg-black/40 p-2;
    & + b {
      @apply ml-3;
    }
  }
}
.badge {
  @apply grid h-[1.7em] place-items-center rounded-md px-2 py-1 text-[1.25rem] font-bold leading-none text-theme-mainbg;
  @apply absolute bottom-[calc(100%_-_0.8em)] left-2 z-1;
  @apply border-[3px] border-theme-green bg-theme-green;
}

.services-wrap {
  @apply grid w-full gap-6;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}
